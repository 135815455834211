import { useNetapp, useNetappStacked1, useNetappStacked2, useNetappStacked3 } from "../../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default",
      },
      title: "NetApp",
      uri: "/work/netapp/",
      // dateGmt: "2021-08-05T04:41:07",
      slug: "netapp",
      // modifiedGmt: "2021-09-29T21:06:52",
      seo: {
        canonical: "",
        metaDesc:
          "Learn how Vivid worked with NetApp to educate, engage, and enable channel partners to grow the business.",
        opengraphDescription:
          "Learn how Vivid worked with NetApp to educate, engage, and enable channel partners to grow the business.",
        opengraphSiteName: "Vivid",
        opengraphTitle: "NetApp - Vivid",
        opengraphType: "article",
        title: "NetApp - Vivid",
        twitterDescription: "",
        twitterTitle: "",
        twitterImage: null,
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#bfdab6",
          backgroundGradientDark: "#1e556e",
          backgroundImagePosition: "center",
          backgroundSize: "contain",
          cta: null,
          ctaVariant: "light",
          description: "Educating and engaging partners to grow and succeed",
          descriptionColor: null,
          title: "Unlocking the potential of partners",
          titleColor: null,
          rightImage: {
            altText: "",
            name: "/netapp-hero.svg",
            localFile: {
              publicURL: "/static/8239f470725ef61aa853525ac9e6dbbc/Header-1.svg",
              extension: "svg",
              childImageSharp: null,
            },
          },
          backgroundImage: null,
          overlapSection: null,
        },
        {
          __typename: "Overview",
          client: "NetApp",
          description:
            "In 2020, NetApp launched a bold brand repositioning from storage expert to cloud-led, data-driven digital transformation leader for businesses around the world. With aggressive growth goals and 80% of revenue coming from its channel partners, the company needed to bring its new narrative to life with partners—to build deeper relationships and motivate them to tap the full breadth of its systems, software and services portfolio.<br />\r\n <br />\r\nBy bringing the new NetApp to life through a program of partner-focused business solutions with tools, presentations, infographics, videos and more, we’ve helped NetApp unlock the potential of its diverse and broad partner ecosystem.<br />\r\n",
          industry: "Cloud-led, data-centric software company ",
          solutions: "Strategy, Branding, Copywriting, Design, Video, Project Management",
          title: "Overview",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useNetappStacked1,
                localFile: {
                  publicURL:
                    "/static/eeeb7c52a5666dab505631e5b277a853/dfuzr_success_story_Netapp_work_sample_1.png",
                  extension: "png",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADQklEQVQoz2WTa0zbVRiH/xpjYqbT8YGpWTRZjPGWeJmSaGJ0ZhDN3EQGFgpdC3SuUgTKyspll3oBAUcVRSitZMi439JRRllXKOsAJ527UIQNL4ExMi4bsSxzCGyPZyV+mL5fTs7JeX/nOSfPkQz2M8isx7gw48c/d4WLExNMz8zCzWWcPw/ygsZAoMT83/p7cZGzw+fpOeujrO0omuIKwjJzCUnRISU1D5DQMEDf2FX+WxPTszydmI7TewZb70myv68hwniAlz7K4nHlLtbIUrhni5YgWTiGpkhMbhmS4mA38koPtafHmJmbo3foPNaOLjQmM68m5xAUuZOHorQEKzJYn5TLem0+b+RVo+8Yxnh8jLDCFp5QK2j07aDNJwgfjtPzoGh4TKVnXVwqd7+jRNooR4oycpfSxIZP65GVO5BXuNhe6UZV5SHb9St7u38nzX6O8JJ2VoUr+cqZgMvZiPRsWiGv7StnU34Nbxe18Pp+K/dHp/Fi2XE2VvayWYSEF9uIMjv4oLwTnX0QdV0/Oxt/EpS/kOEYJlhAmdq+YOLSKJKu9RS6Vi8pLV40zad4q6CBB6KSeN9iI6bCQaS5ja0icMs3h9E0DbDryBB73X9g9Iyzu3OET8S4VpmFqckReHcp/tAJYg+6UVd7MNi8FLkGeUpt4JnUA0SXthNdcpiYMjupdR5yu0dJrO0j4rv2lfB2Hx+3nuaRhH2oi0pXAu2+i/gu/8nk/A3m/1oILOrNPxCszEFp6SC1yolKUN7WK7nZy7bSDt78vJqQPVZCC+p5Lv1LQZjJOrmWuPwSpOWFGwHH/H4/U1NTgcCaLg9BMelsK7Gz1dSEouIYHzac5OU9Fh5N2M+qyBSkUAX3blbxvCaTNRGJhAoPb9shLVy/fod7S0vL1HWdEA2xrFXlcF+EltXCgifVuwkRGsnziimst9F3bpDxyUnRcQud+RAGa83KlT2+ESwd3egstYRmF7AhKYvV727nleRsMsqrqDzqZmBklMnZK3ccPDQP/ZdXYCxHXGwy5IrsW0I5/WeE6Y28F7+DWKWSnv4fic/7mm9tnf/7OWI/N5cWaej9Dc+4n55L1+iZXsI7ckEA5HDVf41/ALU0qhiOZOjOAAAAAElFTkSuQmCC",
                      aspectRatio: 1.6528925619834711,
                      src: "/static/eeeb7c52a5666dab505631e5b277a853/f9ff4/dfuzr_success_story_Netapp_work_sample_1.png",
                      srcSet:
                        "/static/eeeb7c52a5666dab505631e5b277a853/5224a/dfuzr_success_story_Netapp_work_sample_1.png 200w,\n/static/eeeb7c52a5666dab505631e5b277a853/d786d/dfuzr_success_story_Netapp_work_sample_1.png 400w,\n/static/eeeb7c52a5666dab505631e5b277a853/f9ff4/dfuzr_success_story_Netapp_work_sample_1.png 800w,\n/static/eeeb7c52a5666dab505631e5b277a853/73f08/dfuzr_success_story_Netapp_work_sample_1.png 1200w,\n/static/eeeb7c52a5666dab505631e5b277a853/063af/dfuzr_success_story_Netapp_work_sample_1.png 1600w,\n/static/eeeb7c52a5666dab505631e5b277a853/ad0ca/dfuzr_success_story_Netapp_work_sample_1.png 2358w",
                      srcWebp:
                        "/static/eeeb7c52a5666dab505631e5b277a853/b0751/dfuzr_success_story_Netapp_work_sample_1.webp",
                      srcSetWebp:
                        "/static/eeeb7c52a5666dab505631e5b277a853/9e195/dfuzr_success_story_Netapp_work_sample_1.webp 200w,\n/static/eeeb7c52a5666dab505631e5b277a853/40a1d/dfuzr_success_story_Netapp_work_sample_1.webp 400w,\n/static/eeeb7c52a5666dab505631e5b277a853/b0751/dfuzr_success_story_Netapp_work_sample_1.webp 800w,\n/static/eeeb7c52a5666dab505631e5b277a853/a7c53/dfuzr_success_story_Netapp_work_sample_1.webp 1200w,\n/static/eeeb7c52a5666dab505631e5b277a853/80926/dfuzr_success_story_Netapp_work_sample_1.webp 1600w,\n/static/eeeb7c52a5666dab505631e5b277a853/07feb/dfuzr_success_story_Netapp_work_sample_1.webp 2358w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useNetappStacked2,
                localFile: {
                  publicURL:
                    "/static/c70346e90a723c1ac07cda279908c6a5/dfuzr_success_story_Netapp_work_sample_2.png",
                  extension: "png",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADXElEQVQozx2TXUzTVxjG/8mWOIggUMAUBWmhHwjVDU3IZGEDBeZaWUSmzgtMtt2QYfgQll1sk5CxKEmnV0ucjEyduyC7MOpwQP1iKDoFSr8/oFAoMlqg2KLEuey3Q0/y5LxXv/c5z/seKRhaZHklQvTFS54LhZ+t4p/7m6VwJFYvr0QJR1ZZEVpd+we7y4teb6DyAwN1ja28py/iyLEKTtR/hqnvd6Qnd67jsIwSjq6xfnp+u8p23VsU7XlXqITikjL2Vuqp2G9g3/sGSvZWotIWcKq9g+86jTSfbOJMxynOGjsZHfkLyT72ELfbSUg4WT8/nO9Ceu0N4pPS2JAoI0WexRallq25eSSLWpaRzevxm2g8+QULoRCW8VEePxzi/tBdLOYRJIfHjdnmZGFxhf8E8Meun4lLSEa2OSMGS8/WoC0qpfhADVlqHZkKNXGiUUNzCzN+Hy6HDY8w5HbZsVvHkQZumbj9532u9fZhdbg50/k9G+IT2ZKlID0jkwyNjsLy/ah270GRtxN1/g7iNqVR39CIb9KN3WbBYbdgEzCbZQzppslE/91Brt7oZcRsoeN0JxuT08hUqpDL5ajVGt4pKeajmkOo8vLJ0ebF4vi8/gTTPg9OhzUmhwDbrQI4+GCY5XCYSDQSy/DS5V9jT01NlyNLSeFQeSmfHK7G+O03fHiwRuSpEdmm0tLaylJwjkmviwm3A5/XiddpRXK6XEQjEYLBIK/+fSWAv5Au30qSLI2E1M1sy1WhVeWSo1azTZFDZnYu8QlJ1NXVMeefEBAbbgFy28cx37qOFF5eYnExRHBhgbW1F/T09KBU5lBdUcrXn36MRqGgsKCA7UolKSmppIlGMnHr9XoBsRCaDzDltmF7PIxDTFu6fOUKja1f8tPFS7xce875rm6OVVfzoNvI2LlmTh8/QPvxg5S9XcTONwvR6XaQn69j167dtLe1EXw6g/PeH9gGBwhMTyE1NDWRKDruK6/EPzUp1qabgb4+Ar5Jbl44x9mjZbTUHqH68NHYYKqqqjAId7W1tRiNRpGfk/lZP7P+aQJ+Aey9cY2Otq+42H0B34QHr2c902fMB2bwupw8ut2P3WLG/OQR90z94nv1Mjw0yITY38XgAvNzAZ7Ozgh3PvyTXv4Hnm95+J99aYkAAAAASUVORK5CYII=",
                      aspectRatio: 1.6528925619834711,
                      src: "/static/c70346e90a723c1ac07cda279908c6a5/f9ff4/dfuzr_success_story_Netapp_work_sample_2.png",
                      srcSet:
                        "/static/c70346e90a723c1ac07cda279908c6a5/5224a/dfuzr_success_story_Netapp_work_sample_2.png 200w,\n/static/c70346e90a723c1ac07cda279908c6a5/d786d/dfuzr_success_story_Netapp_work_sample_2.png 400w,\n/static/c70346e90a723c1ac07cda279908c6a5/f9ff4/dfuzr_success_story_Netapp_work_sample_2.png 800w,\n/static/c70346e90a723c1ac07cda279908c6a5/73f08/dfuzr_success_story_Netapp_work_sample_2.png 1200w,\n/static/c70346e90a723c1ac07cda279908c6a5/063af/dfuzr_success_story_Netapp_work_sample_2.png 1600w,\n/static/c70346e90a723c1ac07cda279908c6a5/8ceb6/dfuzr_success_story_Netapp_work_sample_2.png 2360w",
                      srcWebp:
                        "/static/c70346e90a723c1ac07cda279908c6a5/b0751/dfuzr_success_story_Netapp_work_sample_2.webp",
                      srcSetWebp:
                        "/static/c70346e90a723c1ac07cda279908c6a5/9e195/dfuzr_success_story_Netapp_work_sample_2.webp 200w,\n/static/c70346e90a723c1ac07cda279908c6a5/40a1d/dfuzr_success_story_Netapp_work_sample_2.webp 400w,\n/static/c70346e90a723c1ac07cda279908c6a5/b0751/dfuzr_success_story_Netapp_work_sample_2.webp 800w,\n/static/c70346e90a723c1ac07cda279908c6a5/a7c53/dfuzr_success_story_Netapp_work_sample_2.webp 1200w,\n/static/c70346e90a723c1ac07cda279908c6a5/80926/dfuzr_success_story_Netapp_work_sample_2.webp 1600w,\n/static/c70346e90a723c1ac07cda279908c6a5/6cee9/dfuzr_success_story_Netapp_work_sample_2.webp 2360w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
        {
          __typename: "Quote",
          authorAndTitleCompany: "Tricia Jennett, Sr. Director, Global Partner Marketing",
          quoteText:
            "I’m truly appreciative and grateful that we discovered the amazing Vivid team. Thank you for delivering superior quality and impactful business outcomes for the partner marketing organization.",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useNetappStacked3,
                localFile: {
                  publicURL:
                    "/static/5adf22529eaad60ed0e8a238bf61b52d/dfuzr_success_story_Netapp_work_sample_3.png",
                  extension: "png",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAADiUlEQVQ4y02Ra0zbZRSH+9ElIyZOYJksyMJFbkNuglwWtyEOWGHAhK0Ik9taINxpK8Qim0MEcWyImUanxrhEdPHDgiRLppkaQlDJNDh1ftANKG2h/39p6RUKj2/dF0/yy3nznrzP+Z3zKiTJyu3vZzlcVEt5jZbS020cO6Hm+MlmylQd1Gl6Od78AQnqW0T3/E1Yn5foNyBlFLLHtjg07idzzE/M0EMpTCYTX3w1zVFlPU1dg9Q09lJc0UrRyRYKypoEXENxZTvP5DWQoZogtX2GiK77PGnwkSQA6aN+Dl32Ey/O+/oFcP7OAueH36NU1cmFkffpOzeO/rVL1LUMUPZSN6fr+8jMKSE3MYUXcvOpqmnj+cIqnm6cJqQX9uo3iRCg/QY/4QHgtckbnKrTkV+qRlnZRkWdAU3nm/QYLmMYvMJh5VkiY7LIy3iOvJwiykUT5YkzHGyaY58e4vt9pFzYIuwV4VBI0dJ9nriMYqKSC0jMrebZolZySjrIe1FHUWUPwftTScsqJeXIGVJVg5RVdVJY0c1jDWvs0kCEbpvI3m3CdQKqFcDE7FMogpLZtTebuKxq0vI1BMcqCUsqJSa3loPlBpJ6pogf/ouECZkj9aPo+0aYurNN44SRoNg69lTMsrsFHmnYRJFTqEGp0nH1sykkm4PZuV94PPMs4Y2fEz94l4RxK7GXJOJGjCRcXCdWfZ2Prl4jEPY1I52tem7cnGdyDtQfelE4Npz8PzZddgrP3eTARTtRQ0bS317m6DtGkoeWiRwwEdY+z63vfmbHv43T6YEd8Wh7S2SXyB4UflFwOH0sWlzIdreo+hn55BtCuhbEry1T/bGFT2c30F6XONC7RNrAA1bMEvLaGsZlI6tmC64NF84NNy6nG4Xs8PDtbz5e/XKLmT8EcGeLH+cXiGj+gegBCymvL1MwZib/LRN7Whapffc+frcD+7oDac2KacWEbJXwuj0C6kRhWbXyz6KZ27/K/PnAzoa4dMgWjum+JrhjhRj9ElHaJZ7SLhLUsMjElBF8dmyyHbfThcfl/i8HgHbbOgq7fR1JWhMjGLFJq1glWbj0cmVyht01v/NE6wqhzUaCNVZCX77H3IIZv1esR7IhCWcBdwGgz+PFKtYggHYCkgVdkmVsNhsLd+9Rre7n0ZJpQpushKh+IihzmPQCrQBYxa5cONYfOnSJiQIuA7KYzfwLjBfc4zQcZroAAAAASUVORK5CYII=",
                      aspectRatio: 1.6,
                      src: "/static/5adf22529eaad60ed0e8a238bf61b52d/f9ff4/dfuzr_success_story_Netapp_work_sample_3.png",
                      srcSet:
                        "/static/5adf22529eaad60ed0e8a238bf61b52d/5224a/dfuzr_success_story_Netapp_work_sample_3.png 200w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/d786d/dfuzr_success_story_Netapp_work_sample_3.png 400w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/f9ff4/dfuzr_success_story_Netapp_work_sample_3.png 800w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/73f08/dfuzr_success_story_Netapp_work_sample_3.png 1200w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/063af/dfuzr_success_story_Netapp_work_sample_3.png 1600w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/8ceb6/dfuzr_success_story_Netapp_work_sample_3.png 2360w",
                      srcWebp:
                        "/static/5adf22529eaad60ed0e8a238bf61b52d/b0751/dfuzr_success_story_Netapp_work_sample_3.webp",
                      srcSetWebp:
                        "/static/5adf22529eaad60ed0e8a238bf61b52d/9e195/dfuzr_success_story_Netapp_work_sample_3.webp 200w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/40a1d/dfuzr_success_story_Netapp_work_sample_3.webp 400w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/b0751/dfuzr_success_story_Netapp_work_sample_3.webp 800w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/a7c53/dfuzr_success_story_Netapp_work_sample_3.webp 1200w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/80926/dfuzr_success_story_Netapp_work_sample_3.webp 1600w,\n/static/5adf22529eaad60ed0e8a238bf61b52d/6cee9/dfuzr_success_story_Netapp_work_sample_3.webp 2360w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
